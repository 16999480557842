<template>
  <div class="wrapper">
    <admin-title :title="$route.params.id ? '编辑' : '新增'"></admin-title>
    <a-divider />

    <el-card style="padding:0 0 50px 220px">
      <el-form ref="form" :model="formData" label-width="130px" :rules="rules">
        <el-form-item label="名称" prop="enumText">
          <el-input v-model="formData.enumText" placeholder="名称"></el-input>
        </el-form-item>
        <el-form-item label="值" prop="enumCode">
          <el-input v-model="formData.enumCode" placeholder="值"></el-input>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input v-model="formData.sort" placeholder="排序"></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input type="textarea" v-model="formData.remark"></el-input>
        </el-form-item>
        <el-form-item label=" ">
          <el-button type="info" @click="cancel">取消</el-button>
          <el-button type="primary" @click="save">保存</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
// import { LEADER_POST as leaderPost } from '@/enum/dict.js'
import { addDictValue, getDictValue, editDictValue } from './api'
export default {
  name: 'Index',
  data() {
    return {
      formData: {
        dictCode: this.$route.params.dictCode,
        id: undefined,
        enumText: "",
        enumCode: "",
        sort: 1,
        remark: ""
      },
      rules: {
        enumText: [{ required: true, message: '该项不能为空', trigger: 'blur' }],
        enumCode: [{ required: true, message: '该项不能为空', trigger: 'blur' }],
        sort: [{ required: true, message: '该项不能为空', trigger: 'blur' }],
      },
      // 字典
      // leaderPost
    }
  },

  mounted() {
    if (this.$route.params.id) {
      this.apiQueryDictValueDetail(this.$route.params.id)
    }
  },

  methods: {
    save() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          if (this.formData.id) {
            await editDictValue(this.formData).then(() => {
              this.$message.success('编辑成功')
              this.$router.back()
            })
            //
          } else {
            await addDictValue(this.formData).then(() => {
              this.$message.success('新增成功')
              this.$router.back()
            })
          }
        } else {
          return false
        }
      })
    },
    cancel() {
      this.$router.back(-1)
    },
    handleAvatarSuccess(res, file) {
      this.formData.avatar = res.data.url
    },
    beforeAvatarUpload(file) { },

    async apiQueryDictValueDetail(id) {
      this.formData = await getDictValue(id)
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;

  .el-form /deep/ {
    .el-form-item {
      // display: flex;
      // justify-content: center;
      .el-form-item__content {
        // margin-left: 0 !important;
        width: 50%;
        // .el-select {
        //   // width: 100%;
        // }
      }
      .el-cascader {
        width: 100%;
      }
      .el-select {
        width: 100%;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.el-form /deep/ {
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
}
</style>
